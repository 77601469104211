import { createRef } from 'react';
import { FormEmbalagem } from './Embalagem';
import { FormGtin } from './Gtin';
import { FormProdutoFornecedor } from './ProdutoFornecedor';
import { Select } from './Select';

export interface FormProduto {
  cod_produto: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };
  flg_alcoolico: {
    value: boolean;
    isInvalid: boolean;
    isRequired: boolean;
  };
  cod_gtin_principal: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };
  des_produto: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };
  des_reduzida: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };
  cod_departamento: {
    value: Select;
    isInvalid: boolean;
    isRequired: boolean;
  };
  flg_nao_pis_cofins: {
    value: boolean;
    isInvalid: boolean;
    isRequired: boolean;
  };
  tipo_nao_pis_cofins: {
    value: Select;
    isInvalid: boolean;
    isRequired: boolean;
  };
  des_unidade_compra: {
    value: Select;
    isInvalid: boolean;
    isRequired: boolean;
  };
  qtd_embalagem_compra: {
    value: number | undefined;
    isInvalid: boolean;
    isRequired: boolean;
  };
  val_peso: {
    value: string | undefined;
    isInvalid: boolean;
    isRequired: boolean;
  };
  des_unidade_venda: {
    value: Select;
    isInvalid: boolean;
    isRequired: boolean;
  };
  qtd_embalagem_venda: {
    value: number | undefined;
    isInvalid: boolean;
    isRequired: boolean;
  };
  val_peso_emb: {
    value: string | undefined;
    isInvalid: boolean;
    isRequired: boolean;
  };
  val_peso_bruto: {
    value: string | undefined;
    isInvalid: boolean;
    isRequired: boolean;
  };
  qtd_divisor_preco: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };
  des_unidade_medida_preco: {
    value: Select;
    isInvalid: boolean;
    isRequired: boolean;
  };
  cod_similar: {
    value: Select;
    isInvalid: boolean;
    isRequired: boolean;
  };
  cod_marca: {
    value: Select;
    isInvalid: boolean;
    isRequired: boolean;
  };
  flg_ipv: {
    value: boolean;
    isInvalid: boolean;
    isRequired: boolean;
  };
  flg_envia_balanca: {
    value: boolean;
    isInvalid: boolean;
    isRequired: boolean;
  };
  qtd_dias_validade: {
    value: number;
    isInvalid: boolean;
    isRequired: boolean;
  };
  cod_info_nutricional: {
    value: Select;
    isInvalid: boolean;
    isRequired: boolean;
  };
  flg_envia_pdv: {
    value: boolean;
    isInvalid: boolean;
    isRequired: boolean;
  };
  tipo_multiplicacao: {
    value: Select;
    isInvalid: boolean;
    isRequired: boolean;
  };
  flg_permite_parc: {
    value: boolean;
    isInvalid: boolean;
    isRequired: boolean;
  };
  flg_rastreavel: {
    value: boolean;
    isInvalid: boolean;
    isRequired: boolean;
  };
  cod_tipo_item: {
    value: number;
    isInvalid: boolean;
    isRequired: boolean;
  };

  Gtins: {
    gtins: FormGtin[];
    isInvalid: boolean;
    isRequired: boolean;
  };

  Embalagens: {
    embalagens: FormEmbalagem[];
    isInvalid: boolean;
    isRequired: boolean;
  };

  Fornecedores: {
    fornecedores: FormProdutoFornecedor[];
    isInvalid: boolean;
    isRequired: boolean;
  };
  ipi_tipo: {
    value: Select;
    isInvalid: boolean;
    isRequired: boolean;
  };
  ipi_val: {
    value: string | number;
    isInvalid: boolean;
    isRequired: boolean;
  };
  tipo_movimentacao: {
    value: Select;
    isInvalid: boolean;
    isRequired: boolean;
  };
  per_perda: {
    value: string | number;
    isInvalid: boolean;
    isRequired: boolean;
  };
  flg_explosao_compra: {
    value: boolean;
    isInvalid: boolean;
    isRequired: boolean;
  };
  flg_explosao_venda: {
    value: boolean;
    isInvalid: boolean;
    isRequired: boolean;
  };
}

export type MapeamentoValores = {
  '-1': { entrada: string; saida: string };
  '0': { entrada: string; saida: string };
  '1': { entrada: string; saida: string };
  '2': { entrada: string; saida: string };
  '3': { entrada: string; saida: string };
  '4': { entrada: string; saida: string };
  '5': { entrada: string; saida: string };
};

export const formProdutoBlank = {
  cod_produto: {
    value: '',
    isInvalid: true,
    isRequired: true,
    ref: createRef<any>(),
  },
  flg_alcoolico: {
    value: false,
    isInvalid: false,
    isRequired: false,
  },
  cod_gtin_principal: {
    value: '',
    isInvalid: false,
    isRequired: false,
  },
  des_produto: {
    value: '',
    isInvalid: true,
    isRequired: true,
  },
  des_reduzida: {
    value: '',
    isInvalid: true,
    isRequired: true,
  },
  cod_departamento: {
    value: { label: undefined, value: undefined },
    isInvalid: true,
    isRequired: true,
    // ref: createRef<any>(),
  },
  flg_nao_pis_cofins: {
    value: false,
    isInvalid: false,
    isRequired: false,
  },
  tipo_nao_pis_cofins: {
    value: { label: '', value: undefined },
    isInvalid: false,
    isRequired: false,
  },
  ipi_tipo: {
    value: { label: '', value: undefined },
    isInvalid: false,
    isRequired: false,
  },
  ipi_val: {
    value: '0,0000',
    isInvalid: false,
    isRequired: false,
  },
  des_unidade_compra: {
    value: { label: 'UN', value: 'UN' },
    isInvalid: false,
    isRequired: false,
  },
  qtd_embalagem_compra: {
    value: 1,
    isInvalid: false,
    isRequired: true,
  },
  val_peso: {
    value: '1,000',
    isInvalid: false,
    isRequired: true,
  },
  des_unidade_venda: {
    value: { label: 'UN', value: 'UN' },
    isInvalid: false,
    isRequired: true,
  },
  qtd_embalagem_venda: {
    value: 1,
    isInvalid: false,
    isRequired: false,
  },
  val_peso_emb: {
    value: '1,000',
    isInvalid: false,
    isRequired: true,
  },
  val_peso_bruto: {
    value: '1,000',
    isInvalid: false,
    isRequired: false,
  },
  qtd_divisor_preco: {
    value: '1,000',
    isInvalid: false,
    isRequired: true,
  },
  des_unidade_medida_preco: {
    value: { label: 'UN', value: 'UN' },
    isInvalid: false,
    isRequired: false,
  },
  cod_similar: {
    value: { label: '', value: undefined },
    isInvalid: false,
    isRequired: false,
  },
  cod_marca: {
    value: { label: '', value: undefined },
    isInvalid: false,
    isRequired: false,
  },
  flg_ipv: {
    value: false,
    isInvalid: false,
    isRequired: false,
  },
  flg_envia_balanca: {
    value: false,
    isInvalid: false,
    isRequired: false,
  },
  qtd_dias_validade: {
    value: 0,
    isInvalid: false,
    isRequired: false,
  },
  cod_info_nutricional: {
    value: { label: undefined, value: undefined },
    isInvalid: false,
    isRequired: false,
  },
  flg_envia_pdv: {
    value: true,
    isInvalid: false,
    isRequired: false,
  },
  tipo_multiplicacao: {
    value: { label: 'Sim', value: 1 },
    isInvalid: false,
    isRequired: false,
  },
  flg_permite_parc: {
    value: false,
    isInvalid: false,
    isRequired: false,
  },
  flg_rastreavel: {
    value: false,
    isInvalid: false,
    isRequired: false,
  },
  cod_tipo_item: {
    value: 0,
    isInvalid: false,
    isRequired: false,
  },
  Gtins: {
    gtins: [],
    isInvalid: false,
    isRequired: false,
  },

  Embalagens: {
    embalagens: [],
    isInvalid: false,
    isRequired: false,
  },
  Fornecedores: {
    fornecedores: [],
    isInvalid: false,
    isRequired: false,
  },
  tipo_movimentacao: {
    value: { label: 'Direta', value: 0 },
    isInvalid: false,
    isRequired: false,
  },
  per_perda: {
    value: '0,00',
    isInvalid: false,
    isRequired: false,
  },
  flg_explosao_compra: {
    value: false,
    isInvalid: false,
    isRequired: false,
  },
  flg_explosao_venda: {
    value: false,
    isInvalid: false,
    isRequired: false,
  },
};

export const formProdutoBlankNew = {
  des_produto: '',
  des_reduzida: '',
  cod_departamento: {
    label: '',
    value: undefined,
  },
  cod_trib_entrada: {
    label: undefined,
    value: undefined,
  },
  per_veda_cred_lim: '',
  cod_tributacao: {
    label: undefined,
    value: undefined,
  },
  tipo_nao_pis_cofins: {
    label: undefined,
    value: undefined,
  },

  ipi_tipo: {
    value: -1,
    label: 'Nenhum',
  },
  ipi_val: '',
  des_unidade_compra: { label: 'UN', value: 'UN' },
  qtd_embalagem_compra: 1,
  val_peso: '1,000',
  des_unidade_venda: { label: 'UN', value: 'UN' },
  qtd_embalagem_venda: 1,
  val_peso_emb: '1,000',
  val_peso_bruto: '1,000',
  qtd_divisor_preco: '1,000',
  des_unidade_medida_preco: { label: 'UN', value: 'UN' },
  cod_similar: { label: '', value: undefined },
  cod_marca: { label: '', value: undefined },
  tecla_balanca: '',
  cod_receita: { label: 'Selecione a Receita', value: undefined },
  cod_info_nutricional: { label: 'Selecione a Inf. Nutr.', value: undefined },
  cod_tara: { label: 'Selecione a Tara', value: undefined },
  tipo_multiplicacao: { label: 'Sim', value: 1 },
  qtd_etiqueta: 0,
  tipo_movimentacao: { label: 'Direta', value: 0 },
  per_perda: '',
  num_lote_rast: '',
  qtd_lote_rast: 0,
  cod_agreg_rast: 0,
  des_tab_sped: {
    label: '',
    value: undefined,
  },
  cod_tab_sped: {
    label: '',
    value: undefined,
  },
  des_sped: '',
  cod_sped: '',
};
