import { GridRowParams } from '@material-ui/data-grid';
import { format } from 'date-fns';
import { nanoid } from 'nanoid';
import FormDefault from '~/components/FormDefault';
import { HistoricoProduto } from '~/components/HistoricoProduto';
import Loja from '~/components/Loja';
import Search from '~/components/Search';
import { LojaContext } from '~/context/loja';
import api from '~/services/api';
import {
  formatCurrencyAsText,
  numberToText,
  transformAsCurrency,
} from '~/utils/functions';
/* eslint-disable no-else-return */
import React, { createRef, useContext, useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import moment from 'moment';

import AbaEquivalentes from './Abas/AbaEquivalentes';
import AbaFornecedor from './Abas/AbaFornecedor';
import AbaGeral from './Abas/AbaGeral';
import { AbaInterface } from './Abas/AbaInterface';
import { useProduto } from './ProdutoContext';
import { Associado, Embalagem } from './protocols';
import { Container } from './styles';
import { IGtin, IProdutoFornecedor } from './types';
import { loadingContext } from '~/context/loading';
import { moneyFormat } from '../../utils/functions';
import { MdOutlineWarning } from 'react-icons/md';
import useAuth from '~/hooks/useAuth';
import { Select } from '~/pages/Produto/protocols';
import { createDateWithoutTimezone } from '~/utils/createDateWithoutTimezone ';

const MySwal = withReactContent(Swal);

const Produto: React.FC = () => {
  const { user } = useAuth();
  const {
    formProduto,
    changeFormProduto,
    formProdutoLoja,
    changeFormProdutoLoja,
    isUpdate,
    toggleInitInicitalizado,
    toggleIsShowSearch,
    toggleIsUpdate,
    handleClearForms,
    isShowSearch,
    handleSelectedLoja,
    selectedLoja,
    handleDuplicateForm,
    mainLoja,
    changeMainLoja,
    setValue,
    handleSubmit,
    handleResetClearField,
    handleChangeIsDuplicate,
    handleChangeIsDuplicateForUpdate,
    isDuplicateForUpdate,
    setTabGeral,
    setNcmIsFromTheProduct,
    tab,
    setTab,
    optionsEntradaPisCofins,
    setAssociadosFilho,
    setAssociadosPai,
    similares,
    setHasErroTipoIncidente,
    removeEditNaoPisCofinsCache,
    getDepartamentos,
    getIdContabeis,
    getTipoItem,
    departamentos,
    getValues,
  } = useProduto();
  const [resetLoja, setResetLoja] = useState(false);
  const { loja } = useContext(LojaContext);
  const { setLoading } = useContext(loadingContext);

  const [lojas, setLojas] = useState<number | number[]>();

  useEffect(() => {
    if (Array.isArray(lojas)) {
      const mLoja = lojas[0];
      if (mLoja) {
        if (mLoja !== mainLoja.cod_loja) {
          api.get(`/lojas/${mLoja}`).then((res) => {
            const { data } = res.data;
            changeMainLoja({
              des_uf: data.des_uf,
              cod_loja: data.cod_loja,
              des_fantasia: data.des_fantasia,
              des_cidade: data.des_cidade,
              cst_pis_cof_ent_aliq_zero: data.cst_pis_cof_ent_aliq_zero,
              cst_pis_cof_ent_monof: data.cst_pis_cof_ent_monof,
              cst_pis_cof_ent_subst: data.cst_pis_cof_ent_subst,
              cst_pis_cof_ent_imune: data.cst_pis_cof_ent_imune,
              cst_pis_cof_ent_susp: data.cst_pis_cof_ent_susp,
              cst_pis_cof_entrada: data.cst_pis_cof_entrada,
              cst_pis_cof_saida_aliq_zero: data.cst_pis_cof_saida_aliq_zero,
              cst_pis_cof_saida_monof: data.cst_pis_cof_saida_monof,
              cst_pis_cof_saida_subst: data.cst_pis_cof_saida_subst,
              cst_pis_cof_saida_imune: data.cst_pis_cof_saida_imune,
              cst_pis_cof_saida_susp: data.cst_pis_cof_saida_susp,
              cst_pis_cof_saida: data.cst_pis_cof_saida,
            });
          });
        }
      }
    } else if (lojas) {
      api.get(`/lojas/${lojas}`).then((res) => {
        const { data } = res.data;
        changeMainLoja({
          des_uf: data.des_uf,
          cod_loja: data.cod_loja,
          des_fantasia: data.des_fantasia,
          des_cidade: data.des_cidade,
          cst_pis_cof_ent_aliq_zero: data.cst_pis_cof_ent_aliq_zero,
          cst_pis_cof_ent_monof: data.cst_pis_cof_ent_monof,
          cst_pis_cof_ent_subst: data.cst_pis_cof_ent_subst,
          cst_pis_cof_ent_imune: data.cst_pis_cof_ent_imune,
          cst_pis_cof_ent_susp: data.cst_pis_cof_ent_susp,
          cst_pis_cof_entrada: data.cst_pis_cof_entrada,
          cst_pis_cof_saida_aliq_zero: data.cst_pis_cof_saida_aliq_zero,
          cst_pis_cof_saida_monof: data.cst_pis_cof_saida_monof,
          cst_pis_cof_saida_subst: data.cst_pis_cof_saida_subst,
          cst_pis_cof_saida_imune: data.cst_pis_cof_saida_imune,
          cst_pis_cof_saida_susp: data.cst_pis_cof_saida_susp,
          cst_pis_cof_saida: data.cst_pis_cof_saida,
        });
      });
    }
  }, [lojas]);

  /**
   * FORM PADRÃO
   */
  const onRowClick = async (param: GridRowParams) => {
    try {
      await getDepartamentos();
      await getIdContabeis();
      await getTipoItem();
      const { row } = param;
      const { cod_produto, cod_loja, des_similar } = row;
      handleSelectedLoja(row.cod_loja);
      const res = await api.get(`/produto/${cod_produto}/${cod_loja}`);
      const { data, success, message } = res.data;
      if (!success) {
        throw new Error(message);
      }

      handleResetClearField();

      const {
        cod_departamento,
        cod_gtin_principal,
        cod_info_nutricional,
        cod_tipo_item,
        cod_similar,
        des_produto,
        des_reduzida,
        des_unidade_compra,
        des_unidade_venda,
        flg_alcoolico,
        flg_envia_balanca,
        flg_envia_pdv,
        flg_ipv,
        flg_nao_pis_cofins,
        flg_permite_parc,
        flg_rastreavel,
        qtd_dias_validade,
        qtd_embalagem_compra,
        qtd_embalagem_venda,
        tipo_multiplicacao,
        tipo_nao_pis_cofins,
        val_peso,
        val_peso_bruto,
        val_peso_emb,
        qtd_divisor_preco,
        des_unidade_medida_preco,
        val_ipi,
        tipo_ipi,
        tipo_movimentacao,
        cod_marca,
        des_tab_sped,
        cod_tab_sped,
        cod_cred_pres,
      } = data.produto;

      const {
        flg_fora_linha,
        tipo_associado,
        cod_ncm,
        tecla_balanca,
        qtd_etiqueta,
        num_lote_rast,
        qtd_lote_rast,
        cod_agreg_rast,
        dta_fabricacao_rast,
        cod_receita,
        cod_tara,
        cod_vasilhame,
        flg_vale_gas,
        flg_lib_fiscal,
        flg_veda_cred,
        flg_veda_cred_lim,
        per_veda_cred_lim,
        flg_explosao_compra,
        flg_explosao_venda,
        per_perda,
        flg_atualiza_custo_decomp,
      } = data.produtoLoja;
      handleChangeIsDuplicateForUpdate(true);
      setValue('cod_produto', cod_produto);
      setValue('des_produto', des_produto);
      setValue('cod_tipo_item', cod_tipo_item);
      setValue('des_reduzida', des_reduzida);
      setValue('des_sped', des_tab_sped);
      setValue(
        'cod_sped',
        cod_tab_sped && cod_tab_sped.toString().padStart(3, '0'),
      );

      setValue(
        'entrada_pis_cofins',
        cod_cred_pres
          ? optionsEntradaPisCofins.find(
              (option) => option.value === cod_cred_pres,
            )
          : {
              value: null,
              label: '=Saída',
            },
      );
      const ipiVal = formatCurrencyAsText(val_ipi);
      setValue('ipi_val', !ipiVal ? '0,00' : ipiVal);
      setValue('qtd_embalagem_compra', qtd_embalagem_compra);
      setValue('val_peso', numberToText(val_peso));
      setValue('qtd_embalagem_venda', qtd_embalagem_venda);
      setValue('val_peso_emb', numberToText(val_peso_emb));
      setValue('val_peso_bruto', numberToText(val_peso_bruto));
      setValue('qtd_divisor_preco', numberToText(qtd_divisor_preco));
      setValue('tecla_balanca', tecla_balanca);
      setValue('qtd_etiqueta', qtd_etiqueta);
      setValue('per_perda', parseFloat(per_perda).toFixed(2).replace('.', ','));
      setValue('num_lote_rast', num_lote_rast);
      setValue('qtd_lote_rast', qtd_lote_rast);
      setValue('cod_agreg_rast', cod_agreg_rast || 0);
      setValue('flg_fora_linha', flg_fora_linha);
      setValue('flg_alcoolico', flg_alcoolico);
      setValue(
        'cod_departamento',
        departamentos.find(
          (departamento: any) => departamento.value === cod_departamento,
        ) || undefined,
      );

      setValue('cod_ncm', { label: undefined, value: cod_ncm });
      setValue('flg_veda_cred_lim', flg_veda_cred_lim);
      setValue('flg_veda_cred', flg_veda_cred);
      setValue('per_veda_cred_lim', per_veda_cred_lim);
      setValue('flg_nao_pis_cofins', flg_nao_pis_cofins);
      setValue('tipo_nao_pis_cofins', tipo_nao_pis_cofins);
      setValue('des_unidade_compra', {
        label: undefined,
        value: des_unidade_compra,
      });
      setValue('des_unidade_venda', des_unidade_venda);
      setValue('des_unidade_medida_preco', des_unidade_medida_preco);
      setValue('cod_similar', {
        value: cod_similar,
        label: des_similar,
      });
      setValue('cod_marca', cod_marca);
      setValue('flg_ipv', flg_ipv);
      setValue('flg_envia_balanca', flg_envia_balanca);
      setValue('qtd_dias_validade', qtd_dias_validade);
      setValue('cod_receita', cod_receita);
      setValue('cod_info_nutricional', cod_info_nutricional);
      setValue('cod_tara', cod_tara);
      setValue('flg_envia_pdv', flg_envia_pdv);
      setValue('tipo_multiplicacao', tipo_multiplicacao);
      setValue('cod_vasilhame', cod_vasilhame);
      setValue('tipo_movimentacao', tipo_movimentacao);
      setValue('flg_explosao_compra', flg_explosao_compra);
      setValue('flg_atualiza_custo_decomp', flg_atualiza_custo_decomp);
      setValue('flg_explosao_venda', flg_explosao_venda);
      setValue('flg_permite_parc', flg_permite_parc);
      setValue('flg_vale_gas', flg_vale_gas);
      setValue('flg_lib_fiscal', flg_lib_fiscal);
      setValue(
        'dta_fabricacao_rast',
        dta_fabricacao_rast
          ? moment(
              createDateWithoutTimezone(dta_fabricacao_rast),
              'DD/MM/YYYY',
            ).format('YYYY-MM-DD')
          : null,
      );
      setValue('qtd_lote_rast', qtd_lote_rast);
      setValue('flg_rastreavel', flg_rastreavel);
      setValue('tipo_associado', tipo_associado);

      const id_contabeis_options = getValues('id_contabeis_options');
      const tipo_itens = getValues('tipo_itens');
      if (tipo_itens.length > 0) {
        setValue(
          'cod_tipo_item',
          tipo_itens.find((item: any) => item.value === row.cod_tipo_item),
        );
      }
      if (id_contabeis_options.length > 0) {
        setValue(
          'cod_id_ctb',
          id_contabeis_options.find(
            (item: any) => item.value === row.cod_id_ctb,
          ),
        );
      }

      setTab('geral');

      const perVedaCredLim =
        per_veda_cred_lim === 0 ? '0,00' : moneyFormat(per_veda_cred_lim);

      setValue('per_veda_cred_lim', perVedaCredLim);

      const gtins = data.gtins.map((item: IGtin) => {
        return {
          cod_gtin: {
            value: item.cod_gtin,
            isRequired: false,
            isInvalid: false,
            invalidLabel: '',
          },
        };
      });

      const gtiPrincipalIndex = gtins.findIndex(
        (gtin: any) => gtin.cod_gtin.value === cod_gtin_principal,
      );
      const gtinPrincipal = gtins.find(
        (gtin: any) => gtin.cod_gtin.value === cod_gtin_principal,
      );
      gtins.splice(gtiPrincipalIndex, 1);
      gtins.unshift(gtinPrincipal);

      const associados = data.associados.map((item: Associado) => {
        return {
          cod_associado: {
            value: item.cod_produto,
            isRequired: true,
            isInvalid: false,
            invalidLabel: '',
          },
          des_produto: {
            value: item.des_produto,
            isRequired: false,
            isInvalid: false,
            invalidLabel: '',
          },
          des_departamento: {
            value: item.des_departamento,
            isRequired: false,
            isInvalid: false,
            invalidLabel: '',
          },
          flg_atual_vda_assoc: {
            value: item.flg_atual_vda_assoc,
            isRequired: false,
            isInvalid: false,
            invalidLabel: '',
          },
          per_acresc_assoc: {
            value: item.per_acresc_assoc,
            isRequired: true,
            isInvalid: false,
            invalidLabel: '',
          },
          per_desc_assoc: {
            value: item.per_desc_assoc,
            isRequired: true,
            isInvalid: false,
            invalidLabel: '',
          },
          qtd_estoque: {
            value: item.qtd_estoque,
            isInvalid: false,
            isRequired: false,
          },
        };
      });
      const filter = associados.map((item: any) => {
        return {
          id: item.cod_associado.value,
          cod_produto: item.cod_associado.value,
          des_produto: item.des_produto.value,
          des_departamento: item.des_departamento.value,
          per_desc_assoc: formatCurrencyAsText(item.per_desc_assoc.value),
          qtd_estoque: item.qtd_estoque.value,
          per_acresc_assoc: formatCurrencyAsText(item.per_acresc_assoc.value),
          flg_atual_vda_assoc: item.flg_atual_vda_assoc.value,
        };
      });

      if (tipo_associado === 1) {
        setAssociadosPai(filter);
        setValue('associados_pai', filter);
      }

      if (tipo_associado === 2) {
        setAssociadosFilho(filter);
        setValue('associados_filho', filter);
      }
      const embalagens = data.embalagens.map((item: Embalagem) => {
        return {
          cod_gtin: {
            value: { label: undefined, value: item.cod_gtin },
            isRequired: true,
            isInvalid: false,
            invalidLabel: '',
          },
          des_unidade: {
            value: { label: undefined, value: item.des_unidade },
            isRequired: true,
            isInvalid: false,
            invalidLabel: '',
          },
          qtd_embalagem: {
            value: item.qtd_embalagem,
            isRequired: true,
            isInvalid: false,
            invalidLabel: '',
          },
          val_peso_emb: {
            value: numberToText(item.val_peso_emb),
            isRequired: true,
            isInvalid: false,
            invalidLabel: '',
          },
          val_peso_bruto: {
            value: numberToText(item.val_peso_bruto),
            isRequired: true,
            isInvalid: false,
            invalidLabel: '',
          },
          flg_principal: {
            value: item.flg_principal,
            isRequired: true,
            isInvalid: false,
            invalidLabel: '',
          },
          qtd_divisor_preco: {
            value: numberToText(item.qtd_divisor_preco),
            isRequired: true,
            isInvalid: false,
            invalidLabel: '',
          },
          des_unidade_medida_preco: {
            value: { label: '', value: item.des_unidade_medida_preco },
            isRequired: true,
            isInvalid: false,
            invalidLabel: '',
          },
        };
      });

      setValue('embalagens', embalagens);

      const fornecedores = data.fornecedores.map((item: IProdutoFornecedor) => {
        const unidades = item.unidades.map((un) => {
          return {
            id: {
              value: nanoid(),
              isRequired: true,
              isInvalid: false,
            },
            des_referencia: {
              value: un.des_referencia,
              isRequired: true,
              isInvalid: false,
              invalidLabel: '',
            },
            des_unidade_compra: {
              value: {
                label: un.des_unidade_compra,
                value: un.des_unidade_compra,
              },
              isRequired: true,
              isInvalid: false,
              invalidLabel: '',
            },

            qtd_embalagem_compra: {
              value: un.qtd_embalagem_compra,
              isRequired: true,
              isInvalid: false,
              invalidLabel: '',
            },
          };
        });
        return {
          cod_fornecedor: {
            value: item.cod_fornecedor,
            isRequired: true,
            isInvalid: false,
            invalidLabel: '',
          },
          consulta: {
            value: item.cod_fornecedor,
            isRequired: false,
            isInvalid: false,
            invalidLabel: '',
          },
          des_fornecedor: {
            value: item.des_fornecedor,
            isRequired: false,
            isInvalid: false,
            invalidLabel: '',
          },
          num_cpf_cnpj: {
            value: formatarCNPJ(item?.num_cpf_cnpj),
            isInvalid: true,
            isRequired: true,
          },
          flg_principal: {
            value: item.flg_principal,
            isRequired: false,
            isInvalid: false,
            invalidLabel: '',
          },
          flg_fabricante: {
            value: item.flg_fabricante,
            isRequired: false,
            isInvalid: false,
            invalidLabel: '',
          },
          Unidades: {
            unidades,
            isRequired: false,
            isInvalid: false,
            invalidLabel: '',
          },
        };
      });
      setValue('fornecedores', fornecedores);
      changeFormProduto({
        ...formProduto,
        cod_produto: {
          value: cod_produto,
          isInvalid: false,
          isRequired: false,
        },
        flg_alcoolico: {
          ...formProduto.flg_alcoolico,
          value: flg_alcoolico,
          isInvalid: false,
          isRequired: false,
        },
        cod_tipo_item: {
          value: cod_tipo_item,
          isInvalid: false,
          isRequired: false,
        },
        cod_gtin_principal: {
          value: cod_gtin_principal,
          isInvalid: false,
          isRequired: false,
        },
        des_produto: {
          ...formProduto.des_produto,
          value: des_produto,
          isInvalid: false,
          isRequired: true,
        },
        des_reduzida: {
          value: des_reduzida,
          isInvalid: false,
          isRequired: true,
        },
        cod_departamento: {
          value: { label: undefined, value: cod_departamento },
          isInvalid: false,
          isRequired: false,
          // ref: createRef<any>(),
        },
        flg_nao_pis_cofins: {
          value: flg_nao_pis_cofins,
          isInvalid: false,
          isRequired: false,
        },
        tipo_nao_pis_cofins: {
          value: { label: undefined, value: tipo_nao_pis_cofins },
          isInvalid: false,
          isRequired: false,
        },
        des_unidade_compra: {
          value: { label: undefined, value: des_unidade_compra },
          isInvalid: false,
          isRequired: true,
        },
        qtd_embalagem_compra: {
          value: qtd_embalagem_compra,
          isInvalid: false,
          isRequired: true,
        },
        val_peso_emb: {
          value: numberToText(val_peso_emb),
          isInvalid: false,
          isRequired: false,
        },
        des_unidade_venda: {
          value: { label: undefined, value: des_unidade_venda },
          isInvalid: false,
          isRequired: false,
        },
        qtd_embalagem_venda: {
          value: qtd_embalagem_venda,
          isInvalid: false,
          isRequired: false,
        },
        val_peso: {
          value: numberToText(val_peso),
          isInvalid: false,
          isRequired: false,
        },
        val_peso_bruto: {
          value: numberToText(val_peso_bruto),
          isInvalid: false,
          isRequired: false,
        },
        cod_similar: {
          value: { label: undefined, value: cod_similar },
          isInvalid: false,
          isRequired: false,
        },
        cod_marca: {
          value: { label: undefined, value: cod_marca },
          isInvalid: false,
          isRequired: false,
        },
        flg_ipv: {
          value: flg_ipv,
          isInvalid: false,
          isRequired: false,
        },
        flg_envia_balanca: {
          value: flg_envia_balanca,
          isInvalid: false,
          isRequired: false,
        },
        qtd_dias_validade: {
          value: qtd_dias_validade,
          isInvalid: false,
          isRequired: false,
        },
        cod_info_nutricional: {
          value: { label: undefined, value: cod_info_nutricional },
          isInvalid: false,
          isRequired: false,
        },
        flg_envia_pdv: {
          value: flg_envia_pdv,
          isInvalid: false,
          isRequired: false,
        },
        flg_permite_parc: {
          value: flg_permite_parc,
          isInvalid: false,
          isRequired: false,
        },
        tipo_multiplicacao: {
          value: { label: undefined, value: tipo_multiplicacao },
          isInvalid: false,
          isRequired: false,
        },
        flg_rastreavel: {
          value: flg_rastreavel,
          isInvalid: false,
          isRequired: false,
        },
        qtd_divisor_preco: {
          value: numberToText(qtd_divisor_preco),
          isRequired: false,
          isInvalid: false,
        },
        des_unidade_medida_preco: {
          value: { label: '', value: des_unidade_medida_preco },
          isRequired: false,
          isInvalid: false,
        },

        Gtins: {
          gtins,
          isInvalid: false,
          isRequired: false,
        },

        Embalagens: {
          embalagens,
          isInvalid: false,
          isRequired: false,
        },

        Fornecedores: {
          fornecedores,
          isInvalid: false,
          isRequired: false,
        },
        ipi_tipo: {
          value: { value: tipo_ipi, label: '' },
          isInvalid: false,
          isRequired: false,
        },
        ipi_val: {
          value: !ipiVal ? 0.0 : ipiVal,
          isInvalid: false,
          isRequired: false,
        },
        tipo_movimentacao: {
          value: { label: '', value: tipo_movimentacao },
          isInvalid: false,
          isRequired: false,
        },
        per_perda: {
          value: String(per_perda).replace('.', ','),
          isInvalid: false,
          isRequired: false,
        },
        flg_explosao_compra: {
          value: flg_explosao_compra,
          isInvalid: false,
          isRequired: false,
        },
        flg_explosao_venda: {
          value: flg_explosao_venda,
          isInvalid: false,
          isRequired: false,
        },
      });
      changeFormProdutoLoja({
        ...formProdutoLoja,
        flg_fora_linha: {
          value: flg_fora_linha,
          isInvalid: false,
          isRequired: false,
        },
        flg_vale_gas: {
          value: flg_vale_gas,
          isInvalid: false,
          isRequired: false,
        },
        flg_lib_fiscal: {
          value: flg_lib_fiscal,
          isInvalid: false,
          isRequired: false,
        },
        tipo_associado: {
          value: tipo_associado,
          isInvalid: false,
          isRequired: false,
        },
        cod_ncm: {
          // value: { label: undefined, value: cod_ncm },
          value: cod_ncm,
          isInvalid: false,
          isRequired: false,
          ref: createRef<any>(),
        },
        tecla_balanca: {
          value: tecla_balanca,
          isInvalid: false,
          isRequired: false,
        },
        qtd_etiqueta: {
          value: qtd_etiqueta,
          isInvalid: false,
          isRequired: false,
        },
        num_lote_rast: {
          value: num_lote_rast,
          isInvalid: false,
          isRequired: false,
        },
        cod_agreg_rast: {
          value: cod_agreg_rast || 0,
          isInvalid: false,
          isRequired: false,
        },
        qtd_lote_rast: {
          value: qtd_lote_rast,
          isInvalid: false,
          isRequired: false,
        },
        dta_fabricacao_rast: {
          value: dta_fabricacao_rast
            ? moment(
                createDateWithoutTimezone(dta_fabricacao_rast),
                'DD/MM/YYYY',
              ).format('YYYY-MM-DD')
            : undefined,
          isInvalid: false,
          isRequired: false,
        },
        cod_receita: {
          value: { label: undefined, value: cod_receita },
          isInvalid: false,
          isRequired: false,
        },
        cod_tara: {
          value: { label: undefined, value: cod_tara },
          isInvalid: false,
          isRequired: false,
        },
        cod_vasilhame: {
          value: cod_vasilhame,
          isInvalid: false,
          isRequired: false,
          ref: createRef<any>(),
        },

        Associados: {
          associados,
          isInvalid: false,
          isRequired: false,
        },
        flg_veda_cred: {
          value: flg_veda_cred,
          isInvalid: false,
          isRequired: false,
        },
        flg_veda_cred_lim: {
          value: flg_veda_cred_lim,
          isInvalid: false,
          isRequired: false,
        },
        per_veda_cred_lim: {
          value: String(per_veda_cred_lim).includes('.')
            ? String(per_veda_cred_lim).replace('.', ',')
            : per_veda_cred_lim,
          isInvalid: false,
          isRequired: false,
        },
        flg_atualiza_custo_decomp: {
          value: flg_atualiza_custo_decomp,
          isInvalid: false,
          isRequired: false,
        },
      });

      setValue('reset_pis_cofins', false);
      toggleIsUpdate(true);
      toggleIsShowSearch(false);
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const formatarCNPJ = (cnpj: string) => {
    return cnpj.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      '$1.$2.$3/$4-$5',
    );
  };

  async function handleSave() {
    handleChangeIsDuplicate(false);
    setValue('duplicaForm', false);
    setTab('geral');
    setTabGeral('icms');
    setValue('initInicializado', true);
    onSave();
  }

  const validaTipoItemNcmDepartamento = async (dataForm: any) => {
    const res = await api.post('/produto/ncm-departamento/', {
      cod_ncm: dataForm.cod_ncm.value,
    });

    const { data } = res.data;
    const ncm = data;

    const hasTipoWithoutUf = ncm.filter(
      (item: any) =>
        item.des_sigla === mainLoja.des_uf &&
        dataForm.cod_tipo_item.value === item.cod_tipo_item,
    );

    if (
      !ncm.some(
        (item: any) => dataForm.cod_tipo_item.value === item.cod_tipo_item,
      )
    ) {
      const AlertTable = (
        <div>
          <div>
            <MdOutlineWarning style={{ fontSize: '50px', color: '#FDDF54' }} />
            <h5>NCM não tem tipificação correspondente à do departamento.</h5>
          </div>
          <div>
            Departamento:
            {dataForm.cod_tipo_item.label}
          </div>
          <div>
            Vá ao cadastro deste NCM e adicione a tipificação correspondente.
          </div>
        </div>
      );

      MySwal.fire({
        width: 650,
        html: AlertTable,
      });
      return true;
    }

    if (hasTipoWithoutUf.length === 0) {
      const AlertTable = (
        <div>
          <div>
            <MdOutlineWarning style={{ fontSize: '50px', color: '#FDDF54' }} />
            <h5>
              NCM selecionado não tem tributação informada para a unidade
              federativa da loja.
            </h5>
          </div>
          <div>
            Vá até o cadastro de NCM e adicione a tributação referente ao estado
          </div>
          <div>{mainLoja.des_uf}</div>
        </div>
      );

      MySwal.fire({
        width: 650,
        html: AlertTable,
      });

      return true;
    }

    return false;
  };

  const onSave = handleSubmit(async (dataForm) => {
    if (
      dataForm.flg_nao_pis_cofins &&
      (dataForm.tipo_nao_pis_cofins.value === -1 ||
        dataForm.tipo_nao_pis_cofins.value === undefined)
    ) {
      setTabGeral('pisCofins');
      setHasErroTipoIncidente(true);
      return;
    }
    if (await validaTipoItemNcmDepartamento(dataForm)) {
      return;
    }

    if (dataForm.flg_envia_balanca) {
      if (Number(dataForm.cod_produto) > Number('000000999999')) {
        toast.warn('Produto de balança com Digíto Alto!');
        return false;
      }
    }
    if (!dataForm.cod_produto) {
      setTab('geral');
      window.scrollTo(0, 0);
      return false;
    }

    if (
      dataForm.des_produto &&
      dataForm.des_reduzida &&
      dataForm.cod_departamento.value &&
      !dataForm.cod_ncm.value
    ) {
      toast.warning('Vincule um NCM ao produto');
      window.scrollTo(0, 0);
      return false;
    }

    if (transformAsCurrency(dataForm.val_peso) <= 0) {
      toast.warning('Peso deve ser informado.');
      return false;
    }
    let hasInvalidFornecedor;
    if (dataForm.fornecedores) {
      hasInvalidFornecedor = dataForm.fornecedores.find((fornecedor: any) => {
        return fornecedor.Unidades.unidades.length < 1;
      });
    }

    if (hasInvalidFornecedor) {
      toast.warning(
        `O Fornecedor com CNPJ: ${formatarCNPJ(
          hasInvalidFornecedor?.num_cpf_cnpj?.value,
        )} está sem embalagem vinculada, é necessário o vínculo de ao menos uma embalagem`,
      );
      return false;
    }
    // eslint-disable-next-line prettier/prettier
    const cod_gtin = `${'0'.repeat(13)}${Number(
      formProduto.cod_produto.value,
    )}`.slice(-13);
    let isGtinplu = false;
    if (formProduto.Gtins.gtins.length < 1) {
      setLoading(false);

      const { isConfirmed } = await Swal.fire({
        title:
          'Código de barras não inserido, deseja criar um código de barras a partir do PLU?',
        showDenyButton: true,
        confirmButtonText: 'SIM',
        denyButtonText: 'Não',
      });
      if (isConfirmed) {
        isGtinplu = true;
      } else {
        isGtinplu = false;
        toast.warning('O Produto deve conter ao menos um GTIN vinculado.');
        return false;
      }
    }

    const dataGtins = formProduto.Gtins.gtins.map((item) => {
      return {
        cod_gtin: item.cod_gtin.value,
      };
    });

    let dataAssociados = [];

    const associados =
      formProdutoLoja.tipo_associado.value === 1
        ? dataForm.associados_pai
        : dataForm.associados_filho;

    if (associados) {
      dataAssociados = associados.map((item: any) => {
        return {
          cod_produto: item.cod_produto,
          per_desc_assoc: transformAsCurrency(item.per_desc_assoc),
          per_acresc_assoc: transformAsCurrency(item.per_acresc_assoc),
          flg_atual_vda_assoc: item.flg_atual_vda_assoc,
        };
      });
    }

    let dataProdutoFornecedor = [];
    if (dataForm.fornecedores) {
      dataProdutoFornecedor = dataForm.fornecedores.map((item: any) => {
        const dataUnidades = item.Unidades.unidades.map((unidade: any) => {
          return {
            des_referencia: unidade.des_referencia.value,
            qtd_embalagem_compra: unidade.qtd_embalagem_compra.value,
            des_unidade_compra: unidade.des_unidade_compra.value.value,
          };
        });
        return {
          cod_fornecedor: item.cod_fornecedor.value,
          flg_principal: item.flg_principal.value,
          flg_fabricante: item.flg_fabricante.value,
          unidades: dataUnidades,
        };
      });
    }

    const codReceita =
      dataForm.cod_receita.value === -1 ? null : dataForm.cod_receita.value;

    const dataProdutoLoja = {
      flg_fora_linha: dataForm.flg_fora_linha,
      tipo_associado: dataForm.tipo_associado,
      cod_ncm: dataForm.cod_ncm.value,
      tecla_balanca: dataForm.tecla_balanca,
      qtd_etiqueta: dataForm.qtd_etiqueta || 0,
      num_lote_rast: dataForm.num_lote_rast,
      qtd_lote_rast: dataForm.qtd_lote_rast,
      cod_agreg_rast: dataForm.cod_agreg_rast || undefined,
      dta_fabricacao_rast: dataForm.dta_fabricacao_rast,
      cod_receita: codReceita,
      cod_tara: dataForm.cod_tara.value === -1 ? null : dataForm.cod_tara.value,
      flg_vale_gas: dataForm.flg_vale_gas,
      flg_lib_fiscal: dataForm.flg_lib_fiscal,
      cod_vasilhame: dataForm.cod_vasilhame,
      flg_veda_cred: dataForm.flg_veda_cred,
      flg_veda_cred_lim: dataForm.flg_veda_cred_lim,
      per_veda_cred_lim: String(dataForm.per_veda_cred_lim)
        ? String(dataForm.per_veda_cred_lim).replace(',', '.')
        : 0,
      associados: dataAssociados,
      flg_atualiza_custo_decomp: dataForm.flg_atualiza_custo_decomp,
    };

    let dataEmbalagens = [];
    if (dataForm.embalagens && dataForm.embalagens.length > 0) {
      dataEmbalagens = dataForm.embalagens.map((item: any) => {
        return {
          val_peso_bruto: item.val_peso_bruto.value,
          val_peso_emb: item.val_peso_emb.value,
          qtd_embalagem: item.qtd_embalagem.value,
          cod_gtin: isGtinplu ? cod_gtin : item.cod_gtin.value.value,
          des_unidade: item.des_unidade.value.value,
          qtd_divisor_preco: item.qtd_divisor_preco.value,
          des_unidade_medida_preco: item.des_unidade_medida_preco.value.value,
        };
      });
    }
    const formData = {
      lojas: [mainLoja.cod_loja],
      cod_produto: dataForm.cod_produto,
      cod_gtin_principal: isGtinplu
        ? cod_gtin
        : formProduto.cod_gtin_principal.value,
      des_produto: dataForm.des_produto.trim(),
      des_reduzida: dataForm.des_reduzida.trim(),
      qtd_embalagem_compra: dataForm.qtd_embalagem_compra,
      des_unidade_compra: dataForm.des_unidade_compra.value,
      qtd_embalagem_venda: dataForm.qtd_embalagem_venda,
      des_unidade_venda: dataForm.des_unidade_venda.value,
      val_peso: transformAsCurrency(dataForm.val_peso),
      val_peso_emb: transformAsCurrency(dataForm.val_peso_emb),
      val_peso_bruto: transformAsCurrency(dataForm.val_peso_bruto),
      flg_alcoolico: dataForm.flg_alcoolico,
      cod_departamento: dataForm.cod_departamento.value,
      cod_similar: dataForm.cod_similar.value,
      cod_marca: dataForm.cod_marca.value,
      flg_envia_balanca: dataForm.flg_envia_balanca,
      flg_ipv: dataForm.flg_ipv,
      qtd_dias_validade: dataForm.qtd_dias_validade,
      flg_envia_pdv: dataForm.flg_envia_pdv,
      flg_permite_parc: dataForm.flg_permite_parc,
      tipo_multiplicacao: dataForm?.tipo_multiplicacao?.value,
      flg_rastreavel: dataForm.flg_rastreavel,
      cod_info_nutricional:
        dataForm.cod_info_nutricional.value === -1
          ? null
          : dataForm.cod_info_nutricional.value,
      flg_nao_pis_cofins: dataForm.flg_nao_pis_cofins,
      des_tab_sped: dataForm.des_sped,
      cod_tab_sped: dataForm.cod_sped,
      cod_cred_pres: !dataForm.entrada_pis_cofins
        ? null
        : dataForm.entrada_pis_cofins.value,
      tipo_nao_pis_cofins: dataForm.flg_nao_pis_cofins
        ? dataForm?.tipo_nao_pis_cofins?.value
        : -1,
      qtd_divisor_preco: dataForm.qtd_divisor_preco,
      ipi_tipo: dataForm.ipi_tipo.value,
      ipi_val:
        dataForm.ipi_tipo.value === '0'
          ? 0
          : Number(String(dataForm.ipi_val).replace(',', '.')),
      des_unidade_medida_preco: dataForm.des_unidade_medida_preco.value,
      produtoLoja: dataProdutoLoja,
      gtins: isGtinplu ? [{ cod_gtin }] : dataGtins,
      embalagens: dataEmbalagens,
      fornecedores: dataProdutoFornecedor,
      tipo_movimentacao: dataForm.tipo_movimentacao.value,
      per_perda:
        dataForm.per_perda === ''
          ? 0
          : String(dataForm.per_perda).replace(',', '.'),
      flg_explosao_compra: dataForm.flg_explosao_compra,
      flg_explosao_venda: dataForm.flg_explosao_venda,
      cod_tipo_item: dataForm.cod_tipo_item.value,
      cod_id_ctb: dataForm.cod_id_ctb.value,
    };

    if (lojas) {
      if (typeof lojas === 'number') {
        formData.lojas = [lojas];
      } else {
        formData.lojas = lojas;
      }
    }

    if (formData.lojas.length < 1) {
      toast.warn('Não há nenhuma loja selecionada.');
      return false;
    }
    setLoading(true);
    setTab('geral');
    if (isUpdate) {
      try {
        const cod_produto = formProduto.cod_produto.value;
        const res = await api.put(`/produto/${cod_produto}`, formData);
        const { success, message } = res.data;
        if (!success) {
          throw new Error(message);
        }
        toast.success(message);
        setValue('initInicializado', false);

        if (dataForm.duplicaForm && isDuplicateForUpdate) {
          duplicateForm();
          toggleIsShowSearch(false);
          setLoading(false);
          return true;
        }
        toggleInitInicitalizado(false);
        toggleIsShowSearch(true);
        onClear();
        setLoading(false);
        return true;
      } catch (e: any) {
        toast.error(e.message);
        setLoading(false);
        return false;
      } finally {
        removeEditNaoPisCofinsCache();
        setLoading(false);
        setTab('geral');
      }
    }

    try {
      const res = await api.post('/produto', formData);
      const { success, message } = res.data;
      if (!success) {
        throw new Error(message);
      }
      toast.success(message);
      setValue('initInicializado', false);
      if (!dataForm.duplicaForm && !isDuplicateForUpdate) {
        onClear();
        setLoading(false);
        return;
      }

      if (dataForm.duplicaForm) {
        setValue('initInicializado', false);
        duplicateForm();
        setLoading(false);
        return;
      }

      toggleIsShowSearch(true);
      setLoading(false);
    } catch (e: any) {
      toast.error(e.message);
      setLoading(false);
      return false;
    }
  });

  async function handleDelete() {
    toast.error('Função Desabilitada');
  }

  const duplicateForm = () => {
    handleChangeIsDuplicate(true);
    setTab('geral');
    setTabGeral('icms');
    toggleIsShowSearch(false);
    toggleIsUpdate(false);
    handleDuplicateForm();
    setValue('duplicaForm', false);
    setNcmIsFromTheProduct(true);
  };

  async function handleDuplicate() {
    setValue('duplicaForm', true);
    setValue('initInicializado', true);
    await onSave();
  }

  function onCancel() {
    setTab('geral');
    setLojas(loja.cod_loja);
    handleSelectedLoja(loja.cod_loja);
    toggleInitInicitalizado(false);
    handleClearForms();
    toggleIsShowSearch(true);
    toggleIsUpdate(false);
    handleChangeIsDuplicateForUpdate(false);
    removeEditNaoPisCofinsCache();
  }

  function onNew() {
    setLojas(mainLoja.cod_loja);
    handleSelectedLoja(mainLoja.cod_loja);
    toggleInitInicitalizado(false);
    setTab('geral');
    setNcmIsFromTheProduct(true);
    handleClearForms();
    toggleIsShowSearch(false);
    toggleIsUpdate(false);
    handleChangeIsDuplicateForUpdate(false);
    removeEditNaoPisCofinsCache();
    getIdContabeis();
  }

  function onClear() {
    setTabGeral('icms');
    setTab('geral');
    handleClearForms();
    removeEditNaoPisCofinsCache();
  }

  function handleLojas(value: any) {
    setLojas(value);
  }

  return (
    <Container>
      {isShowSearch && (
        <Search codTela={28} newData={onNew} onRowClick={onRowClick} />
      )}

      {!isShowSearch && (
        <FormDefault
          codTela={28}
          onSave={handleSave}
          codigoRegistro={[
            {
              value: formProduto.cod_produto.value,
              des_campo: 'Código',
            },
          ]}
          title={formProduto.des_produto.value || 'Cadastro de Produto'}
          subTitle={`LOJA ${mainLoja.cod_loja} - ${mainLoja.des_fantasia} - ${mainLoja.des_cidade}/${mainLoja.des_uf}`}
          onCancel={onCancel}
          isUpdate={isUpdate}
          onNew={onNew}
          onDelete={handleDelete}
          onClearFields={onClear}
          isDuplicate
          isDelete={false}
          onDuplicate={handleDuplicate}
          onReturnSearch={onCancel}
        >
          {isUpdate && (
            <HistoricoProduto
              codProduto={formProduto.cod_produto.value}
              codLoja={selectedLoja}
              user={user}
            />
          )}
          <div className="row">
            <div
              className="col-sm-12 col-md-12"
              style={
                isUpdate ? { margin: '1rem 0rem' } : { marginBottom: '1rem' }
              }
            >
              <Loja
                selectedLoja={selectedLoja}
                isMulti
                onChange={(value) => {
                  setResetLoja(false);
                  handleLojas(value);
                }}
                resetLojas={resetLoja}
              />
            </div>
          </div>

          <Tabs
            id="controlled-produto"
            activeKey={tab}
            onSelect={(k) => setTab(k)}
          >
            <Tab eventKey="geral" title="Geral">
              <AbaGeral />
            </Tab>

            <Tab eventKey="equivalentes" title="Equivalentes">
              <AbaEquivalentes />
            </Tab>

            <Tab eventKey="fornecedor" title="Fornecedor">
              <AbaFornecedor />
            </Tab>

            <Tab eventKey="interface" title="Interface">
              <AbaInterface />
            </Tab>
          </Tabs>
        </FormDefault>
      )}
    </Container>
  );
};
export default Produto;
