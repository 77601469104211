export interface FormGtin {
  cod_gtin: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
    isNew?: boolean;
  };
}

export const formGtinBlank = {
  cod_gtin: {
    value: '',
    isInvalid: false,
    isRequired: false,
    invalidLabel: undefined,
  },
};
